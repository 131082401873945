import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/Slider";
import About from "../../components/about/About";
// import Resume from "../../components/resume/Resume";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from "../../components/useDocumentTitle";
import Projects from "../../components/projects/Projects";
// import Education from "../../components/education/Education"
import Seperator from "../../components/seperator/Seperator";
import Skills from "../../components/skills/Skills"
import Certifications from "../../components/Certifications/Certifications";
import Resume2 from "../../components/resume/Resume2";
import Education2 from "../../components/education/Education2";
import Extracurriculars from "../../components/Extracurriculars/Extracurriculars";


const HomeLightSidebarTwo = () => {
  // State to track the current mode (default to "light")
  const [themeMode, setThemeMode] = useState("light");

  // Function to toggle between light and dark mode
  const toggleDarkMode = () => {
    // Toggle the mode
    const newMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newMode);

    // Update the body class based on the mode
    document.body.classList.remove("theme-light", "theme-dark");
    document.body.classList.add(`theme-${newMode}`);

    // Save the mode to local storage
    localStorage.setItem("themeMode", newMode);
  };

  // Set the document title
  useDocumentTitle("Dikshit Khandelwal");

  // Use useEffect to apply the default theme class when the component mounts
  useEffect(() => {
    const savedThemeMode = localStorage.getItem("themeMode");
    if (savedThemeMode) {
      setThemeMode(savedThemeMode);
    } else {
      // If no theme mode is saved in local storage, use the default "light" mode
      setThemeMode("light");
    }

    // Apply the theme class to the body based on the theme mode
    document.body.classList.remove("theme-light", "theme-dark");
    document.body.classList.add(`theme-${savedThemeMode || "light"}`);
  }, []);

  return (
    <div className={`main-left ${themeMode === "dark" ? "theme-dark" : "theme-light"}`}>
      <Header />

      <Slider toggleDarkMode={toggleDarkMode} themeMode={themeMode} /> 
      
      <About />

      <br />
      <br />

      <Certifications />

      {/* <Resume /> */}
      
      <Seperator />

      <Resume2 />

      <Seperator />

      <Extracurriculars />

      <Seperator />

      <Skills />

      <Seperator />

      {/* <Education /> */}

      <Education2 />

      <Seperator />

      <Projects />

      {/* <ContactInfo /> */}
            
      <Footer />

    </div>
  );
};

export default HomeLightSidebarTwo;
