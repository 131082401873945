const Seperator = () => {
    return (

    <div
        className="separated"
        style={{
            backgroundImage: `url(${
            process.env.PUBLIC_URL + "img/border-dark.png"
            })`,
        }}
    >

    </div>

    );
};

export default Seperator;