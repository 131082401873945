import React from "react";

const CertificationsContnet = [
  {
    img: "oswp",
    awardName: "OSWP (OffSec Wireless Professional)",
    awardFor: "March 2024",
    link:"https://www.credential.net/078bc5e0-239a-4289-8818-647b4eb33ab0",
    id: "Credential ID: 97595807",
    delayAnimation: "0",
  },
  {
    img: "SecurityPlus",
    awardName: "CompTIA Security+",
    awardFor: "November 2023",
    link: "http://verify.CompTIA.org",
    id: "Credential ID: 48Y3WVR6DNFQ14K8",
    delayAnimation: "100",
  },
  {
    img: "PentestPlus",
    awardName: "CompTIA Pentest+",
    awardFor: "December 2023",
    link: "http://verify.CompTIA.org",
    id: "Credential ID: C75VB746BF1E1W94",
    delayAnimation: "200",
  },
];

const Certifications = () => {
  return (

    <section id="certifications" className="title">
      <div className="container">
        <h3>Certifications</h3>
        <div className="certifications">
        {CertificationsContnet.map((val, i) => (
          <div
            className="col-lg-5 m-15px-tb"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-02 d-flex align-items-center">
              <div className="icon">
                <img src={`img/about/${val.img}.png`} alt="award" />
              </div>
              <div className="media-body">
                <h6>{val.awardName}</h6>
                <p>Issued Date: {val.awardFor}</p>
                <p> {val.id} | <a href={val.link}>Verify ➡️</a></p>
                
                
              </div>
            </div>
          </div>
          // End .col
        ))}
        </div>
      </div>
    </section>
  );
};

export default Certifications;
