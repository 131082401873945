import React from "react";
import {
  FaTwitter,
  FaGithub,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaTwitter />, link: "https://www.linkedin.com/in/dikshitkhandelwal" },
  { Social: <FaGithub />, link: "https://www.github.com/dikshitkhandelwal" },
];

const Footer = () => {
  return (
    <footer className="footer white">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 my-2">
            <div className="nav justify-content-center justify-content-md-start">
              {SocialShare.map((val, i) => (
                <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                  {val.Social}
                </a>
              ))}
            </div>
            {/* End .nav */}
          </div>
          {/* End .col */}

          <div className="col-md-6 my-2 text-center text-md-end">
            <p>
              © {new Date().getFullYear()} copyright{" "}
              <a
                href="https://dikshitkhandelwal.com"
                target="_blank"
                rel="noreferrer"
              >
                Dikshit Khandelwal
              </a>{" "}
              All right reserved
            </p>
          </div>
          {/* End .col */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
