import React from 'react';
import './Projects.css'; // Assuming you have a CSS file named Projects.css in the same directory

const projectsContent = [

  {
    img: "pam",
    title: "Linux PAM Backdoor/Exploit",
    description: [
      "Develops a unified authentication system for Linux by leveraging the PAM (Pluggable Authentication Modules) framework, introducing a master password concept.",
      "Enables a singular master password to authenticate multiple user accounts across the system, streamlining login processes while maintaining security standards.",
      "Setup involves installation and compilation steps: executing `sudo apt-get install libpam0g-dev gcc make`, compiling the module with `gcc -fPIC -fno-stack-protector -c my_module.c`, and deploying it using `sudo ld -x --shared -o /lib/x86_64-linux-gnu/security/pam_mymodule.so my_module.o`.",
      "Incorporates a strategic module configuration by appending `auth sufficient pam_mymodule.so` at the beginning of `/etc/pam.d/common-auth`, ensuring the master password's efficacy in the authentication sequence."
    ],
    technical: "C Programming, PAM Modules, GCC, Make",
    Date: "October 2023",
    Github: "https://github.com/dikshitkhandelwal/Master-Password-Linux",
  },

  {
    img: "aibot",
    title: "Smart AI Trading Bot",
    description: [
      "Developed a predictive model utilizing Machine Learning (ML) techniques to forecast future prices of Bitcoin, aiming for high accuracy in understanding market dynamics and ensuring model robustness for real-world application.",
      "Leveraged yfinance to fetch historical Bitcoin data from Yahoo Finance, spanning from January 2015 to November 2023, and prepared the data by cleaning and creating new analytical columns to aid in prediction.",
      "Employed advanced ML algorithms, including Random Forest and XGBoost, enhanced with features like rolling averages and trend analysis for periods of 7, 30, 60, 365 days, to model price movements effectively.",
      "Conducted rigorous model evaluations through backtesting and precision scoring, achieving notable improvements in prediction accuracy post hyperparameter tuning of the XGBoost model.",
      "Performed comprehensive data visualization to reveal key historical trends in Bitcoin’s price, utilizing matplotlib for initial closing price trends and advanced plots to understand short-term and long-term price movements."
    ],
    technical: "Machine Learning, Python, yfinance, Random Forest, XGBoost, Data Visualization, Backtesting",
    Date: "November 2023",
  },

  {
    img: "studygroupfinder",
    title: "StudyGroupFinder",
    description: [
      "Developed an interactive web platform aimed at connecting students for collaborative study sessions, utilizing React for dynamic front-end development and PHP for robust back-end functionality.",
      "Engineered a user-friendly interface with seamless navigation options, including 'Home', 'Your Groups', 'Find Group', an 'Account' dropdown, and 'Logout'.",
      "Incorporated a social feed feature allowing users to make posts, join study groups, and engage in academic discussions across various subjects.",
      "Implemented comprehensive user authentication, registration, and group management systems, providing a secure and personalized user experience.",
      "Facilitated real-time updates and notifications to keep users informed about new study opportunities and group activities.",
      "Designed the application to be responsive across devices, ensuring accessibility for students on-the-go."
    ],
    technical: "React, PHP, HTML, CSS, Responsive Web Design, User Authentication",
    Date: "December 2023", // Replace with the actual date of completion
  },
  
  
  {
    img: "tcpserverclient",
    title: "TCP/IP Server-Client Chat",
    description: [
      "Implemented a server-client model over TCP/IP, ensuring optimal performance and reliability for messaging protocols, influenced by Beej’s authoritative network programming guide.",
      "Programmed a high-efficiency chat server to manage and monitor multiple client connections concurrently, leveraging the ‘select()‘ system call for scalable non-blocking I/O operations.",
      "Developed a command-line interface for the chat system, emulating UNIX shell functionality, to parse and execute network commands, enhancing user operability and system interaction.",
      "Validated application functionality and network communication on multiple dedicated hosts, conforming to strict compatibility and performance benchmarks.",
      "Implemented a comprehensive logging framework that captured real-time data transfer and system events, instrumental for debugging and audit trails."
    ],
    technical: "TCP/IP, Beej's Guide to Network Programming, select() System Call, UNIX Shell, C/C++",
    Date: "Fall 2022",
  },
  
  {
    img: "protocols",
    title: "Transport Layer Protocols",
    description: [
      "Developed three reliable data transport protocols (Alternating-Bit, Go-Back-N, and Selective-Repeat) within a simulated network environment using C/C++. This involved engineering protocol mechanisms for reliable unidirectional data transfer, handling packet corruption and loss, and incorporating checksum techniques for data integrity.",
      "Designed efficient packet handling and state management routines for sender and receiver entities, optimizing the simulation of complex networking protocols. A strategic timeout scheme was developed for re-transmission, tailored to dynamic network conditions.",
      "Conducted extensive analysis of protocol performance under various network conditions, focusing on metrics like throughput and reliability. Results were compiled into a comprehensive report. Additionally, the protocol’s functionality was rigorously tested using automated tests, ensuring correctness in scenarios of packet loss, corruption, and varying window sizes."
    ],
    technical: "C/C++, Network Simulation, Alternating-Bit Protocol, Go-Back-N, Selective-Repeat, Data Integrity, Packet Handling",
    Date: "Spring 2023",
  },
  
  {
    img: "malloc",
    title: "Dynamic Memory Allocator in C",
    description: [
      "Designed and implemented a custom dynamic memory allocator in C, mirroring the functionality of the standard 'malloc', 'free', and related memory management functions.",
      "Employed best-fit, worst-fit, and first-fit algorithms for efficient memory allocation and reduced fragmentation, benchmarked against the standard libc allocator.",
      "Incorporated coalescing and splitting techniques to optimize memory utilization and minimize external fragmentation.",
      "Implemented thread-safe memory allocation and deallocation using mutex locks to ensure safe concurrent operations in multi-threaded applications.",
      "Developed comprehensive unit tests to validate functionality, memory leaks, and boundary conditions, ensuring robustness and reliability."
    ],
    technical: "C, Memory Management Algorithms, Thread-Safety, Performance Benchmarking",
    Date: "2022",
  },
  
  {
    img: "conway",
    title: "Conway's Game of Life - Pac-Man Edition in C",
    description: [
      "Fused the classic Conway's Game of Life simulation with the iconic gameplay of Pac-Man, creating a unique, hybrid game entirely developed in C.",
      "Leveraged the SDL library (Simple DirectMedia Layer) for rendering graphics, managing game states, and handling user input, simulating an interactive environment within the constraints of the C programming language.",
      "Implemented custom game logic that integrates the autonomous cell behavior of Conway's Game of Life with player-controlled Pac-Man movements, including dot collection, ghost avoidance, and power-ups.",
      "Engineered an efficient memory management system to handle dynamic game state changes, ensuring optimal performance and stability throughout gameplay.",
      "Designed AI algorithms for ghost behavior, making strategic decisions based on the state of the Game of Life grid and the player's position, offering a challenging yet fair gameplay experience."
    ],
    technical: "C, SDL Library, Game Development, AI Algorithms, Memory Management",
    Date: "Jan 2022", // Replace with the actual date of completion
  },
  
  {
    img: "instant",
    title: "Instant Messenger in C and Python",
    description: [
      "Designed and developed an instant messaging application facilitating real-time communication between users, leveraging the strengths of C for backend development and Python for scripting and frontend functionalities.",
      "Implemented a multi-threaded server in C to handle concurrent client connections, messages, and ensure efficient data transmission across networks.",
      "Utilized Python for developing user-friendly client-side interfaces, as well as for scripting server-side logic to manage user accounts, authentication, and message history.",
      "Incorporated advanced networking concepts including socket programming for network communication, alongside encryption techniques in Python to secure messages.",
      "Achieved a scalable architecture by designing the server to efficiently manage increasing loads, proving the application's reliability through stress testing under various network conditions."
    ],
    technical: "C, Python, Socket Programming, Multi-threading, Encryption",
    Date: "May 2023", // Replace with the actual date of completion
  },
  
  // {
  //   title: "Synchronization: Producer-Consumer Queues",
  //   description: [],
  //   technical: "",
  //   Date: "",
  //   Github: "",
  //   link: "#",
  // },

  {
    img: "pos",
    title: "Barcode Billing System in Scala",
    description: [
      "Engineered a sophisticated point-of-sale (POS) system using Scala, featuring a robust barcode scanning and billing mechanism tailored for retail environments.",
      "Developed the graphical user interface (GUI) utilizing Java and HTML, ensuring a seamless and intuitive user experience across various platforms.",
      "Incorporated advanced features such as dynamic discount calculations, tax computations, and multi-payment options to facilitate comprehensive sales transactions.",
      "Implemented a database management system for inventory tracking, sales reporting, and customer data management, enhancing operational efficiency and business intelligence.",
      "Integrated with external hardware devices such as barcode scanners and receipt printers, enabling a fully automated checkout process.",
      "Achieved high reliability and performance in processing transactions, validated through rigorous testing and real-world retail deployment."
    ],
    technical: "Scala, Java, HTML, GUI Design, Database Management, External Hardware Integration",
    Date: "May 2022", // Replace with the actual date of completion
    Github: "", // Provide if public, or instructions to contact for more info
    link: "#" // Replace "#" with the URL to a demo or additional documentation if available
  },
  
  
  
];

const Projects = () => {
  return (
    <section id="projects" className="projects">
      <div className="container">
        <h3>Projects</h3>
        <div className="row justify-content-start">
          <div className="col">
            <div className="projects-grid">
              {projectsContent.map((project, index) => (
                <div className="project-card" key={index}>
                  <h5>{project.title}</h5>
                  <img src={`img/about/${project.img}.png`} alt={project.title} />
                  <div className="project-content">
                    <p><strong>Date:</strong> {project.Date}</p>
                    <p><strong>Technologies:</strong> {project.technical}</p>
                    <ul className="project-description">
                      {project.description.map((bullet, bulletIndex) => (
                        <li key={bulletIndex}>{bullet}</li>
                      ))}
                    </ul>
                    {/* Other project details */}
                    {project.Github ? (
                      <a href={project.Github} target="_blank" rel="noopener noreferrer">GitHub</a>
                    ) : (
                      <a href="mailto:your.email@example.com">Please contact me for more details on this project and source code</a>
                    )}                  
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
