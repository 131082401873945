import React from "react";

const AboutThree = () => {
  return (
    <>
      <section id="about" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    <img src="img/about/Photo.png" alt="about" />
                  </div>
                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                  <p>Computer Science and Cybersecurity Student</p>
                  <h3>Dikshit Khandelwal</h3>
                </div>
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div
              className="col-lg-7 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="about-info">
                <div className="title">
                  <h3>Biography</h3>
                </div>
                <div className="about-text">
                  <p>
                  I graduated from University at Buffalo with my Bachelors Degree in Computer Science and Minor in Cybersecurity. I have a passion huge for cybersecurity 
                  and a keen interest in learning and developing security tools.
                  </p>
                  <ul>
                  <li>I am also Founder and President of Cybersecurity Club at University at Buffalo (UBSEC)</li>
                  <li>I also love to workout and Play Guitar</li>
                </ul>
                </div>
                <div className="info-list">
                  <div className="row">
                    <div className="col-sm-6">
                      <ul>
                      <li>
                          <label>Email: </label>
                          <span>dikshitk@buffalo.edu</span>
                        </li>
                        {/* <li>
                          <label>Name: </label>
                          <span>Dikshit Khandelwal</span>
                        </li> */}
                        {/* <li>
                          <label>Age: </label>
                          <span>21 years</span>
                        </li>
                        <li>
                          <label>Address: </label>
                          <span>Buffalo</span>
                        </li> */}
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul>
                        {/* <li>
                          <label>Phone: </label>
                          <span>(+1) 716 907 6765</span>
                        </li> */}

                        {/* <li>
                          <label>Freelance: </label>
                          <span>Available</span>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End col */}
          </div>





        </div>
      </section>
    </>
  );
};

export default AboutThree;
