import React from "react";
import { useState } from "react";

const resumeContent = [
    {
    img: "coinbase",
      jobPosition: `Security Engineer Intern`,
      jobLocation: `Remote`,
      jobDuration: `May 2023 - August 2023`,
      timeDuraton: `Full Time`,
      compnayName: "Coinbase",
      jobDescription: [
      'Led the development of a Continuous Assurance Program leveraging the Rego policy language to institute robust security policies grounded in compliance frameworks like NIST and more across 30+ AWS resources, enhancing data confidentiality and improving security infrastructure, thus contributing positively in a team', 
      'Identified 3000 organizational violations, guiding the development team in prioritizing and rectifying the issues, which enhanced the companys\' security posture dramatically and safeguarded critical infrastructure.',
      'Proposed a revamped architectural framework for the ”Dory” tool, facilitating agile operations and enabling smoother integration with various third-party vendors, including leading efforts to understand Deletion API endpoints of vendors like Salesforce, Qualtrics, and Snowflake; a strategy that positioned the tool for future versatile integrations.',
      'Spearheaded the secure integration of third-party vendors into the data deletion tool, collaborating with cross-functional teams to assemble detailed requirements and design a cohesive and secure tool architecture',]
    },

    {
      jobPosition: `Cybersecurity Intern`,
      img: "delawarenorth",
      jobLocation: `Buffalo`,
      jobDuration: `May 2022 - May 2023`,
      timeDuraton: `Full Time`,
      compnayName: "Delaware North",
      jobDescription: [    
      'Conduct vulnerability scans of web applications and endpoint devices using Qualys and CrowdStrike, identifying potential vulnerabilities and remedying issues as they arise.',
      'Troubleshoot and research security incidents using SIEM applications, such as CrowdStrike and Qualys, to ensure the timely identification and resolution of potential threats.',
      'Worked with the team on Cybersecurity Threat Management program to track ongoing vulnerabilities, analyze data, and present it to the team for discussion, improving the overall security posture',
      'Administer SIEM, integrate log sources, install and upgrade SIEM components, and perform health checks.']
  
  },


  
    {
      jobPosition: `Research Assistant`,
      img: "ub",
      jobLocation: `Buffalo`,
      jobDuration: `August 2021 - Present`,
      timeDuraton: `Full Time`,
      compnayName: "CACTILAB",
      jobDescription: [    
      'Led under the supervision of Professor Ziming Zhao deployed a server using VMware ESXi and actively contributed to the development of a CTF Platform, designed to facilitate the practice of security challenges and machine hacking.',
  
      'Spearheaded the development of a comprehensive CTF Platform, enabling users to enhance their skills in security challenges and gain hands-on experience in hacking machines.',
      
      'Conducted thorough code reviews for custom applications, meticulously identifying errors and software weaknesses to ensure robust and secure software.',
      
      'Acquired expertise in application security vulnerabilities and testing techniques, continuously expanding knowledge and staying up-to-date with emerging trends in the field.']
  
    },

    {
      jobPosition: `Software Developer`,
      img: "scrimmage",
      jobLocation: `Buffalo`,
      jobDuration: `Jun 2021 - Sep 2021`,
      timeDuraton: `Partime`,
      compnayName: "Scrimmage",
      jobDescription: [    
      'Programmed as a backend Mobile Application Developer, utilizing Python as the backend language and PostgreSQL as the database. I leveraged my expertise to develop robust and efficient backend systems that supported the functionality and performance of mobile applications.',
  
      'Played a key role in developing functions to enable seamless synchronization of backend operations. I implemented an algorithm that accurately identified logos for input sports team names, enhancing the overall user experience and providing precise results.']
    },
    
    {
      jobPosition: `Teaching Assistant - System Security CSE 427`,
      img: "ub",
      jobLocation: `Buffalo`,
      jobDuration: `January 2023 - Present`,
      timeDuraton: `Full Time`,
      compnayName: "University at Buffalo",
      jobDescription: [    
      'Conducting engaging lectures and leading class discussions to effectively convey complex concepts and foster a dynamic learning environment focused on system security.',
  
      'Designing and delivering hands-on workshops and practical sessions, enabling students to gain practical experience and reinforce their understanding of crucial system security principles and techniques.',
      
      'Developing comprehensive course materials, such as lecture slides, assignments, and supplementary resources, to support students in achieving their learning objectives and deepening their knowledge of system security.',
      
      'Collaborating closely with the course instructor and fellow Teaching Assistants, ensuring consistent grading standards, and collectively developing innovative teaching strategies to enhance student engagement and learning outcomes.']
  
    },
  
    {
      jobPosition: `Teaching Assistant - Data Structure & Algorithms CSE 250 | Buffalo`,
      img: "ub",
      jobLocation: `Buffalo`,
      jobDuration: `August 2022 - Present`,
      timeDuraton: `Partime`,
      compnayName: "University at Buffalo",
      jobDescription: [    
      'Diligently held regular office hours, providing a supportive environment for students to seek clarification on course material and addressing their questions effectively.',
  
      'Assumed the responsibility of grading assignments and exams, ensuring fair evaluation and providing detailed feedback to students to aid their learning and improvement.',
      
      'Actively monitored and managed online discussion forums associated with the course, fostering a collaborative and engaging learning environment for students.',
      
      'Offered personalized support and guidance to students who required extra assistance, going above and beyond to ensure their comprehension and success in the subject matter.',
      
      'Actively participated in course meetings and collaborated with other Undergraduate Teaching Assistants (UTAs), fostering consistency in grading and teaching methodologies, and contributing to an effective learning experience for all students.']
  
    },

  
    {
      jobPosition: `Certified Academic Tutor`,
      img: "ub",
      jobLocation: `Buffalo`,
      jobDuration: `September 2021 - January 2023`,
      timeDuraton: `Partime`,
      compnayName: "University at Buffalo",
      jobDescription: [    
      'Providing comprehensive academic guidance and tutoring services to undergraduate students in CSE 115 and CSE 116 courses, ensuring their understanding and success in the subject matter.',
  
      'Engaging with students through regular weekly meetings, specifically targeting weak areas in concepts related to Python, Scala, and Javascript. These meetings served as a platform for focused discussions, addressing individual challenges, and reinforcing core concepts.',
      
      'Significantly improving students\' code writing skills by imparting effective techniques to understand, analyze, and debug code. I facilitated their ability to identify and rectify bugs, leading to improved code performance and overall code quality.']
    },
  
    {
      jobPosition: `Student Assistant Supervisor`,
      img: "ub",
      jobLocation: `Buffalo`,
      jobDuration: `September 2021 - January 2023`,
      timeDuraton: `Partime`,
      compnayName: "University at Buffalo",
      jobDescription: [    
      'Successfully completed troubleshooting for 800 individuals by providing appropriate solutions and alternatives within specified time limits. Ensured follow-up to guarantee resolution and customer satisfaction. Conducted in-depth troubleshooting and assisted with software installations.',
  
      'Monitored ticket and Service Request queues, promptly addressing and resolving issues. Generated detailed reports for each ticket and issue, which were submitted to the supervisor for further analysis.',
      
      'Provided support for various areas, including the DUO Two Factor Authentication App, WiFi connectivity issues, and student-related concerns. Assisted users with the setup and troubleshooting of the DUO app, resolved WiFi connectivity problems, and offered guidance on a range of student issues.']
    },
  
    {
      jobPosition: `Research Assistant`,
      img: "ub",
      jobLocation: `Buffalo`,
      jobDuration: `June 2021 - May 2021`,
      timeDuraton: `Partime`,
      compnayName: "University at Buffalo",
      jobDescription: [    
      'Worked with Professor Zhanpeng Jin and Project Mentor Seokmin Choi on project “Emotion Recognition using the wearable device”',
      'Working with Developing, and Training the Deep Learning Module using Pytorch',
      'Worked with convert data and making it useful for the Deep Learning Module']
    },
  

  
    {
      jobPosition: `Backened Developer`,
      img: "seedstages",
      jobLocation: `Buffalo`,
      jobDuration: `Sep 2020 - Dec 2020`,
      timeDuraton: `Partime`,
      compnayName: "Seedstages",
      jobDescription: [    
      'Conducted thorough database testing, meticulously removing duplicate entries and cleaning up unwanted data. By ensuring data integrity, I contributed to the overall accuracy and efficiency of the database.',
  
      'Developed functions and methods to enhance the smooth operation of the database, utilizing NoSQL databases such as Firebase and Python as the backend language. This involved implementing logical processes and optimizing database performance to facilitate seamless data management.',
      
      'Took charge of maintaining records for the company\'s database, demonstrating meticulous attention to detail and a commitment to data organization and management. By keeping the database up-to-date and well-maintained, I contributed to the reliability and accessibility of crucial information']
    },
  
    {
      jobPosition: `Web Developer`,
      img: "maheshbhuvan",
      jobLocation: `Buffalo`,
      jobDuration: `Mar 2018 - Apr 2018`,
      timeDuraton: `Partime`,
      compnayName: "MAHESH BHUVAN",
      jobDescription: [    
      'Led the design of the entire website, taking ownership of the creative process from conceptualization to implementation. By leveraging my design skills and understanding of user experience, I created visually appealing and user-friendly websites that aligned with clients\' objectives.',
  
      'Undertook front-end development using HTML and CSS, translating design concepts into fully functional and responsive web pages. I ensured cross-browser compatibility and optimized performance to deliver a seamless user experience.']
    },
  
    {
      jobPosition: `Reseller`,
      img: "amazon",
      jobLocation: `Buffalo`,
      jobDuration: `Mar 2016 - Jan 2018`,
      timeDuraton: `Partime`,
      compnayName: "Amazon",
      jobDescription: [
      'Executed the packaging of orders by retrieving products from the warehouse and ensuring proper packaging and labeling. This involved meticulous attention to detail to ensure accuracy and a high level of quality control.',
  
      'Coordinated with courier facilities to facilitate the shipment of packed orders. This included managing logistics and ensuring timely delivery by coordinating with shipping providers.',
      
      'Assumed responsibility for accepting orders, processing them efficiently, and generating shipping labels. By meticulously managing the order processing workflow, I ensured smooth and accurate order fulfillment.',
      
      'Handled the coordination and handover of packed orders to courier services, ensuring the prompt and secure delivery of customer orders.']
    },
  
    {
      jobPosition: `Co-Founder and CTO`,
      img: "sunkybaba",
      jobLocation: `Buffalo`,
      jobDuration: `May 2018 - Jan 2022`,
      timeDuraton: `Partime`,
      compnayName: "Sunky Baba",
      jobDescription: 
      ['Executed the packaging of orders by retrieving products from the warehouse and ensuring proper packaging and labeling. This involved meticulous attention to detail to ensure accuracy and a high level of quality control.',
  
      'Coordinated with courier facilities to facilitate the shipment of packed orders. This included managing logistics and ensuring timely delivery by coordinating with shipping providers.',
      
      'Assumed responsibility for accepting orders, processing them efficiently, and generating shipping labels. By meticulously managing the order processing workflow, I ensured smooth and accurate order fulfillment.',
      
      'Handled the coordination and handover of packed orders to courier services, ensuring the prompt and secure delivery of customer orders.']
    },
  ];


  const Resume2 = () => {
    const [itemsToShow, setItemsToShow] = useState(3); // Show 3 items initially
    const [descriptionVisibility, setDescriptionVisibility] = useState({}); // State to control visibility of each job description individually
  
    // Toggle description visibility for a specific item
    const toggleDescription = (index) => {
      setDescriptionVisibility(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    };
  
    return (
      <section id="work" className="title">
        <div className="container">
          <h3>Experience</h3>
          <div className="resume-row">
            {resumeContent.slice(0, itemsToShow).map((val, i) => (
              <div
                key={i}
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay={val.delayAnimation}
              >
                <div className="feature-box-02 d-flex align-items-center">
                  <div className="icon">
                    <img src={`img/about/${val.img}.png`} alt="award" />
                  </div>
                  <div className="media-body">
                    <h6>{val.jobPosition} | {val.compnayName} </h6>
                    <p> {val.jobLocation} | {val.timeDuraton}</p>
                    <p>{val.jobDuration}</p>
                    {descriptionVisibility[i] && (
                      <ul>
                        <br />
                        {val.jobDescription.map((desc, index) => (
                          <li key={index}>{desc}</li>
                        ))}
                      </ul>
                    )}
                    <button className="description-btn" 
                            style={{
                              padding: "5px 10px",
                              backgroundColor: "rgba(255, 147, 1, 1)",
                              color: "white", 
                              border: "none", 
                              borderRadius: "5px", 
                              cursor: "pointer"



                            }}
                            onClick={() => toggleDescription(i)}>
                            {descriptionVisibility[i] ? "Hide Description" : "Show Description"}
                    </button>
                  </div>
                </div>
                <br />
              </div>
            ))}
          </div>
  
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {resumeContent.length > itemsToShow ? (
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "rgba(255, 147, 1, 1)",
                  color: "white", 
                  border: "none", 
                  borderRadius: "5px", 
                  cursor: "pointer"
                }}
                onClick={() => setItemsToShow(resumeContent.length)}
              >
                Show More
              </button>
            ) : (
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "rgba(255, 147, 1, 1)",
                  color: "white", 
                  border: "none", 
                  borderRadius: "5px", 
                  cursor: "pointer"
                }}
                onClick={() => setItemsToShow(3)}
              >
                Show Less
              </button>
            )}
          </div>
        </div>
      </section>
    );
  };
  
  export default Resume2;
  