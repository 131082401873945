import React from "react";

const resumeContent = [
    {
      img: "ubsec",
      jobPosition: `President and Founding Member`,
      jobLocation: `Buffalo`,
      jobDuration: `January 24`,
      compnayName: "UBSEC",
    },

    {
        jobPosition: `Blue Team`,
        img: "ists2024",
        jobLocation: `Rochester`,
        jobDuration: `2023 and 2024`,
        compnayName: "ISTS Competition",    
    },
  
    {
      jobPosition: `Red Team`,
      img: "ubnetdef",
      jobLocation: `Buffalo`,
      jobDuration: `August 2023 - October 2023`,
      compnayName: "UBNetDef",
    },

    {
        jobPosition: `Black Team`,
        img: "ubnetdef",
        jobLocation: `Buffalo`,
        jobDuration: `January 2023 - October 2023`,
        compnayName: "UBNetDef",    
    },

    {
        jobPosition: `Blue Team`,
        img: "ccdc",
        jobLocation: `Buffalo`,
        jobDuration: `2024`,
        compnayName: "CCDC Competition",    
    },
    {
        jobPosition: `Pentester`,
        img: "cptc",
        jobLocation: `Buffalo`,
        jobDuration: `2023`,
        compnayName: "CPTC Competition",    
    },
    {
        jobPosition: `Blue Team`,
        img: "ists2023",
        jobLocation: `Rochester`,
        jobDuration: `2023`,
        compnayName: "ISTS Competition",    
    },
    {
        jobPosition: `Blue Team`,
        img: "ubnetdef",
        jobLocation: `Buffalo`,
        jobDuration: `2023`,
        compnayName: "UB Collegiate Lockdown",    
    },
    {
        jobPosition: `Blue Team`,
        img: "ubnetdef",
        jobLocation: `Buffalo`,
        jobDuration: `2023`,
        compnayName: "UB Internal Lockdown",    
    },
  ];


  const Extracurriculars = () => {
    return (
      <section id="certifications" className="title">
        <div className="container">
          <h3>Extracurriculars</h3>
          <div className="row">
            {resumeContent.map((val, i) => (
              <div key={i} className="col-md-6" data-aos="fade-right" data-aos-duration="1200" data-aos-delay={val.delayAnimation}>
                <div className="feature-box-02 d-flex align-items-center">
                  <div className="icon">
                    <img src={`img/about/${val.img}.png`} alt="award" />
                  </div>
                  <div className="media-body">
                    <h6>{val.jobPosition} | {val.compnayName}</h6>
                    <p>{val.jobLocation}</p>
                    <p>{val.jobDuration}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  export default Extracurriculars;
    