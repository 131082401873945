import React from "react";
import { useState } from "react";

const resumeContent = [
    {
    img: "ub",
      Degree: "Bachelors of Science",
      Study: `Major: Computer Science | Minor: Cybersecurity`,
      jobLocation: `Remote`,
      jobDuration: `August 2020 - May 2024`,
      Name: "State University of New York, Univeristy at Buffalo",
      Description: [
        "MTH 153: Calculus 1",
        "CSE 115: Intro to Computer Science I",
        "CSE 116: Intro to Computer Science II",
        "CSE 191: Discrete Structures",
        "CSE 220: Systems Programming",
        "CSE 250: Data Structures and Algorithms",
        "CSE 341: Computer Architecture",
        "CSE 331: Intro Algorithms and Probability",
        "CSE 365: Computer Security",
        "CSE 368: Intro to Artificial Intelligence",
        "CSE 411: Software Security",
        "CSE 410: Software Security",
        "MTH 142: Calculus 2",
        "MTH 309: Intro Linear Algebra",
        "STA 301: Applied Probability and Statistics",
        "CSE 429: Modern Data Structures and Algorithms",
        "CSE 489: Modern Computer Networking ",
        "CSE 498: Security Research",
        "CSE 442: Software Engineering"
    ]
    
    },
    {
        img: "sarvodya",
          Degree: "High School",
          Study: `11th and 12th Grade in Science`,
          jobLocation: `Kota`,
          jobDuration: `August 2018 - May 2020`,
          Name: "Sarvodaya Senior Secondary High School",
          Description: ["Physics", 
          "Mathematics", 
          "Chemistry", 
          "English", 
          "Physical Education"]

    },
    {
        img: "mems",
          Degree: "Elementary and Secondary School",
          Study: `1st Grade to 10th Grade`,
          jobLocation: `Pune`,
          jobDuration: `August 2008 - May 2018`,
          Name: "Mahaveer English Medium School",
          Description: ["Physics", 
          "Mathematics", 
          "Chemistry", 
          "English", 
          "Physical Education", 
          "Biology", 
          "Computer Science", 
          "Hindi", 
          "History", 
          "Geography"]
    },
    
  ];


  const Education2 = () => {
    const [descriptionVisibility, setDescriptionVisibility] = useState({}); // State to control visibility of each job description individually
  
    // Toggle description visibility for a specific item
    const toggleDescription = (index) => {
      setDescriptionVisibility(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    };
  
    return (
      <section id="education" className="title">
        <div className="container">
          <h3>Education</h3>
          <div className="resume-row">
            {resumeContent.map((val, i) => (
              <div
                key={i}
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay={val.delayAnimation}
              >
                <div className="feature-box-02 d-flex align-items-center">
                  <div className="icon">
                    <img src={`img/about/${val.img}.png`} alt="award" />
                  </div>
                  <div className="media-body">
                    <h6>{val.Name} </h6>
                    <h6>Education: {val.Degree}</h6>
                    <h7> {val.Study} </h7>
                    <p>{val.jobDuration}</p>
                    {descriptionVisibility[i] && (
                      <ul>
                        <br />
                        {val.Description.map((desc, index) => (
                          <li key={index}>{desc}</li>
                        ))}
                      </ul>
                    )}
                    <button className="description-btn" 
                            style={{
                              padding: "5px 10px",
                              backgroundColor: "rgba(255, 147, 1, 1)",
                              color: "white", 
                              border: "none", 
                              borderRadius: "5px", 
                              cursor: "pointer"



                            }}
                            onClick={() => toggleDescription(i)}>
                            {descriptionVisibility[i] ? "Hide Revelant Coursework" : "Show Revelant Coursework"}
                    </button>
                  </div>
                </div>
                <br />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  export default Education2;
  