import React from "react";

const skillsData = [
  {
    category: "Programming Languages",
    skills: [
      "Python", "C", "C++", "Go", "Shell Scripting", "Scala", "Java", "JavaScript", "React", "HTML", "CSS"
    ]
  },
  {
    category: "Cybersecurity",
    skills: [
      "Vulnerability Research", "NIST standards", "Encryption mechanisms", "Security incident troubleshooting", "SIEM applications", "Cybersecurity Threat Management"
    ]
  },
  {
    category: "Red Team Tools",
    skills: [
      // Adding a comprehensive list of commonly used Red Team tools
      "Kali Linux", "Parrot Security OS", "Cobalt Strike", "BloodHound", "Mimikatz", "Empire", "Metasploit Framework", "Burp Suite Pro", "Sqlmap", "Hashcat"
    ]
  },
  {
    category: "Developer Tools",
    skills: [
      "VS Code", "Eclipse", "Google Cloud Platform", "Android Studio", "Node.js", "GitHub", "JUnit", "WordPress", "VMware ESXi", "PostgreSQL", "Firebase", "NoSQL databases"
    ]
  },
  {
    category: "Software",
    skills: [
      "VS Code", "Visual Studio", "Postman", "IntelliJ", "Snowflake", "vSphere", "Content Management System (CMS)", "Qualys", "CrowdStrike"
    ]
  },
  {
    category: "Security Tools",
    skills: [
      "Nmap", "Wireshark", "Metasploit", "GDB", "OpenSSL", "Snort", "BurpSuite", "Ansible", "Kubernetes", "Docker"
    ]
  },
  {
    category: "Networking",
    skills: [
      "Firewall", "IDS/IPS", "FTP", "HTTP", "VOIP", "TCP/IP Suite", "SSL/TLS encryption", "Active Directory"
    ]
  },
  {
    category: "Cloud Platform",
    skills: [
      "AWS", "Azure"
    ]
  },
  {
    category: "Web Development",
    skills: [
      "WordPress", "HTML", "CSS"
    ]
  },
  {
    category: "Data Structures & Algorithms",
    skills: [
      "Data Structure and Algorithm concepts"
    ]
  },
];


const Skills = () => {
    const styles = {
      container: {
        padding: '20px 0'
      },
      section: {
        marginBottom: '30px'
      },
      grid: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px'
      },
      item: {
        background: '#f5f5f5',
        padding: '5px 15px',
        borderRadius: '5px',
        fontSize: '14px',
        lineHeight: '1.5'
      }
    };
  
    return (
      <section id="skills" className="title">
        <div className="container">
        <h3>Skills</h3>  
          {skillsData.map((skillSet, index) => (
            <div key={index} style={styles.section}>
              <h5>{skillSet.category}</h5>
              <div style={styles.grid}>
                {skillSet.skills.map((skill, sIndex) => (
                  <div key={sIndex} style={styles.item}>
                    {skill}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  };
  
  export default Skills;
  
