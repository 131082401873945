import React from "react";
import TextLoop from "react-text-loop";

import { FaLinkedinIn, FaGithub} from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";



const conctInfo = {
  email: "dikshitk@buffalo.edu",
  linkedin: "Linkedin",
  github: "Github"
};

const sliderContent = {
  name: "Dikshit Khandelwal",
  description: `I am a Computer Science Senior at the 
  University at Buffalo, New York, with a passion for cybersecurity 
  and a keen interest in learning and developing 
  different security tools.`,
  btnText: " Download Resume",
};

const Slider = ({ toggleDarkMode, themeMode }) => {

  return (
    <>
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <a href={`mailto:${conctInfo.email}`}><AiFillMail size={24}/></a>
            <a href={`https://linkedin.com/in/dikshitkhandelwal`}><FaLinkedinIn size={24} /></a>
            <a href={`https://github.com/dikshitkhandelwal`}> <FaGithub size={24}/></a>
          </div>
        </div>

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              <div className="type-box">
                <h6>Hello, My name is</h6>
                <h1 className="font-alt">{sliderContent.name}</h1>
                <TextLoop>
                  <p className="loop-text lead">Computer Science Student</p>
                  <p className="loop-text lead">Security Enthusiast</p>
                  <p className="loop-text lead">Security Software Developer</p>
                  <p className="loop-text lead">Community Assistant</p>
                  {/* <p className="loop-text lead">Aspiring Pentester</p> */}
                </TextLoop>{" "}
                <p className="desc">{sliderContent.description}</p>
                <ul>
                  <li>Comptia Security+ Certified</li>
                  <li>Comptia Pentest+ Certified</li>
                  <li>OSWP Certified</li>
                  <li>HTB (Hack The Box) World Rank: 800</li>
                  <li>Top 5% in TryHackMe CTF room</li>
                </ul>
                <div className="mt-4">
                  <a
                    className="px-btn px-btn-white"
                    href="img/resume.pdf"
                    download
                  >
                    {sliderContent.btnText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div
          className="hb-me"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/slider/avatar.png"
            })`,
          }}
        ></div>
        
      </section>

    </>
  );
};

export default Slider;
